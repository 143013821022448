import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import VueCookies from "vue-cookies"
import store from './store';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@/assets/main.css';

createApp(App)
    .use(PrimeVue)
    .use(router)
    .use(store)
    .use(VueCookies)
    .mount('#app')