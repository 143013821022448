import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  const { client_name, primary_color, background_color, sidebar_text_color, secondary_color } = store.getters["styles/getStyles"]
  const root = document.documentElement;
  root.style.setProperty("--sidebar-bg-color", primary_color);
  root.style.setProperty("--sidebar-link-color", sidebar_text_color);
  root.style.setProperty("--default-button-color", secondary_color);
  root.style.setProperty("--default-bg-color", background_color);
  
  document.title = client_name + " Login";
  next();
});

export default router
