
import { defineComponent } from "vue";

import store from "@/store";

export default defineComponent({
  name: "App",
  beforeCreate() {
    store.dispatch("styles/setTheme", process.env.VUE_APP_STYLES);
  },
});
