import { createStore } from "vuex";
import { RootState } from "@/types/state";
import createPersistedState from "vuex-persistedstate";

// Data Modules
import { styles } from "@/store/data/styles";

export default createStore<RootState>({
  modules: {
    styles
  },
  plugins: [createPersistedState()]
});
