import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-1 h-full" }
const _hoisted_2 = {
  id: "app",
  class: "grid mr-0"
}
const _hoisted_3 = { class: "col p-1 comp-transition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, { name: "side" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view, {
              style: {"margin-top":"0.2em"},
              class: "ml-0"
            })
          ])
        ]),
        _: 1
      })
    ])
  ]))
}